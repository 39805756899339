#campaigns-list {
    padding: 4vh;
}

.header-campaign {
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 40px;
    padding: 3vh 0;
    color: #023e8a;
    text-align: center;
}

.tab-header {
    display: flex;
    justify-content: center;
}

.tab-header .MuiTabs-root {
    /* width: 100%; */
}
.tab-header .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer {
    justify-content: space-around;
}
.tab-header .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-textColorInherit{
    opacity: 1  ;
    padding-bottom: 1rem;
    font-size: 1rem;
    width: 320px;
    margin-left: 10px;
    margin-right: 10px;
}

.campaign-list__tab-container {
    margin-top: 4vh;
}

.text-transform-none {
    text-transform: none !important;
}
.tab-header .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-textColorInherit {
    padding-bottom: 0;
}

.tab-header .MuiButtonBase-root {
    border-radius: 50px !important;
    margin-bottom: 10px !important;
    background: #E0E0E0 !important;
}
.tab-header .Mui-selected {
    background: #0D44AC4A !important;
}

@media screen and (max-width: 680px) {
    .tab-header .MuiTabs-root {
        width: 100%;
    }
    .tab-header .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-textColorInherit {
        width: 40%;
        margin-left: 5px;
        margin-right: 5px;
    }
}