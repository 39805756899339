[data-page="get-funded"] {
    .header {
        display: table;
        position: relative;
        width: 100%;
        height: 62vh;
        overflow: hidden;

        .header-background {
            position: absolute;
            width: auto;
            height: auto;
            min-width: 100%;
            min-height: 100%;
        }

        .header-body {
            display: table-cell;
            position: relative;
            vertical-align: middle;
            text-align: center;
            width: 100%;
            padding: 20px;
            z-index: 9;

            h1 {
                font-family: 'Raleway';
                font-size: 48px;
                font-weight: 700;
                line-height: 64px;
                color: #fff;
            }
        }

        @media (max-width: 679px) {
            height: 250px;

            .header-background {
                left: 0;
            }

            .header-body {
                h1 {
                    font-size: 2.25rem;
                }
            }
        }
    }
    
    .content-body {
        .blue {
            background-color: #0D44AC;
        }

        .grey {
            background-color: #F2F2F2;
        }
        
        .fs-1 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 1.438rem;
            line-height: 2.063rem;
            color: #333333;

            > svg {
                font-size: 3rem;
                position: relative;
                top: 15px;
                color: #4F4F4F;
                padding-right: 10px;
            }
        }

        .fs-2 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 1.188rem;
            line-height: 2.063rem;
        }

        .fs-3 {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 700;
            font-size: 1.563rem;
            line-height: 2.188rem;
            margin-bottom: 0;
            
            @media (max-width: 679px) {
                font-size: 1.25rem;
                line-height: 1.75rem;
            }
        }


        .solution {
            margin-top: 50px;
            padding-top: 20px;
            padding-bottom: 20px;
            position: relative;

            img {
                height: 750px;
                position: relative;
                left: 100px;

                @media (max-width: 679px) {
                    width: 100%;
                    height: auto;
                    left: 0;
                }
            }

            .item {
                position: absolute;
                top: 0;
                right: 10px;
                width: 500px;
                height: 750px;

                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;                

                .card {
                    display: block;
                    width: 100%;
                    background-color: #fff;
                    border-radius: 20px;
                    padding: 0px 30px 10px;
                    box-shadow: -10px 24px 24px rgba(0, 0, 0, 0.1), 11px -6px 25px rgba(0, 0, 0, 0.1);
                    margin-top: 60px;
                    margin-bottom: 60px;
                    padding-top: 20px;
                    
                    h4 {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 1.563rem;
                        line-height: 2.188rem;
                        margin: 10px;
                    }
    
                    ul {
                        padding-left: 16px;
    
                        li {
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 1rem;
                            line-height: 1.313rem;
                            padding-top: 10px;
                            padding-bottom: 10px;
                        }
                    }
    
                    &.blue {
                        color: #fff;
                        background-color: #0C74DD;
    
                        h4 {
                            color: #fff;
                        }
    
                        ul li {
                            color: #fff;
                        }
                    }
                }

                @media (max-width: 679px) {
                    position: relative;
                    top: 0;
                    right: 0;
                    width: 85%;
                    height: auto;
                    margin: auto;

                    .card {
                        margin-top: 20px;
                        margin-bottom: 0px;

                        ul {
                            li {
                                font-size: 1rem;
                                line-height: 1.75rem;
                            }
                        }
                    }
                }
            }
        }

        .proccess {
            .item-title {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 700;
                font-size: 1.438rem;
                line-height: 2.063rem;
                color: #4F4F4F;
            }

            ul {
                li {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.625rem;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    color: #4F4F4F;
                }
            }

            @media (max-width: 679px) {
                img {
                    width: 85%;
                }
                
                .item-title {
                    font-size: 1.6rem;
                }

                ul li {
                    font-size: 1rem;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }
        }

        .transparent-cost {
            ul {
                li {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1.125rem;
                    line-height: 1.625rem;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    color: #4F4F4F;

                    @media (max-width: 679px) {
                        font-size: 1rem;
                        line-height: 1.425rem;
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }
            }
        }

        .form-getfunded {
            .MuiOutlinedInput-root {
                border-radius: 10px;
            }
        }
    }
}