[data-page="blog"] {
  padding: 20px 0 50px;

  .fade-in {
    animation: fadeIn .5s ease-in;
  }

  .list-of-blogs {
    margin-bottom: 15px;

    .item-blog {
      h3.blog-title {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 2.625rem;
        color: #4F4F4F;
        margin-bottom: 20px;
        margin-top: 10px;
        min-height: 85px;
      }

      small.time {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.625rem;
        color: #0D44AC;
      }

      .image-blog {
        border-radius: 10px;
        overflow: hidden;
        width: 100%;
        height: 300px;
        margin-top: 20px;
        margin-bottom: 20px;

        > img {
          min-height: 100%;
          width: 100%;
          // width: auto;
        }
      }

      p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.75rem;
        color: #4F4F4F;
      }

      .blog-link {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 2.25rem;
        color: #0D44AC;
        transition: all 0.3s;

        > svg {
          position: relative;
          top: 5px;
          left: 10px;
        }

        &:hover {
          color: darken($color: #0D44AC, $amount: 15%);
        }
      }
    }

    @media (max-width: 679px) {
      padding-right: 0;

      .item-blog {
        .image-blog {
          width: 100%;
          height: 250px;

          > img {
            height: auto;
            width: 100%;
          }
        }
      }
    }
  }

  .right-side {
    padding-left: 20px;
    padding-top: 0;

    .search-input {
      width: 100%;
      
      .MuiOutlinedInput-root {
        border-radius: 56px;
      }

      .PrivateNotchedOutline-root-20, 
      .jss20 {
        top: 0;
        left: -2px;
      }
    }

    .label-hide {
      .PrivateNotchedOutline-legend-21,
      .jss21 {
        display: none;
      }
    }

    @media (max-width: 679px) {
      padding-left: 0;
      padding-top: 0px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0
  } 
    
  to {
    opacity: 1
  }
}