@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
html {
    font-size: 16px !important;

    @media (max-width: 679px) {
        font-size: 14 !important;
    }
}

[data-page="legal"] {
    font-family: 'Raleway', sans-serif;

    .mui-justify-center {
        justify-content: center;
    }

    .px-2 {padding-left: 2em;padding-right: 2em;}

    section {
      &.space {
        padding: 50px 0;

        @media (max-width: 679px) {
            padding: 35px 0;
        }
      }

      &.blue {
        background-color: #023E8A;
      }
    }

    .header {
        display: table;
        position: relative;
        width: 100%;
        height: 70vh;
        overflow: hidden;

        .header-background {
            position: absolute;
            width: auto;
            height: auto;
            min-width: 100%;
            min-height: 100%;
        }

        .header-body {
            display: table-cell;
            position: relative;
            vertical-align: middle;
            text-align: center;
            width: 100%;
            padding: 20px;
            z-index: 9;

            h1 {
                font-size: 3.813rem;
                color: #fff;
            }
        }

        @media (max-width: 679px) {
            height: 300px;

            .header-background {
                left: 0;
            }

            .header-body {
                h1 {
                    font-size: 2rem;
                }
            }
        }
    }

    .sub-header {
        background-color: #0D44AC;
        padding: 30px;
        margin-bottom: 30px;

        p {
            font-family: 'Roboto', sans-serif;
            font-size: 1.188rem;
            font-weight: 400;
            line-height: 1.813rem;
            color: #fff;

            @media (max-width: 679px) {
                font-size: 1rem;
            }
        }
    }

    .center-content {
        display: table;
        width: 100%;
        height: 100%;

        .body {
            display: table-cell;
            vertical-align: middle;

            &.space-left {
                padding-left: 2rem;
            }
        }

        @media (max-width: 679px) {
            .body {
                &.space-left {
                    padding-left: 0;
                }
            }
        }
    }

    .title {
        color: #023E8A;
        font-style: normal;
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 3.5rem;

        &.center {
            text-align: center;
        }

        @media (max-width: 679px) {
            font-size: 1.75rem;
            line-height: 2rem;
        }
    }

    p {
        color: #4F4F4F;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.625rem;
        text-align: justify;

        &.center {
            text-align: center;
        }

        @media (max-width: 679px) {
            font-size: 1rem;
            line-height: 1.425rem;
        }
    }

    .tab {
      .tab-header {
        padding-top: 40px;
        padding-bottom: 40px;

        .btn {
          font-family: 'Raleway';
          font-style: normal;
          font-weight: 700;
          font-size: 1.575rem;
          line-height: 2rem;

          background: none;
          border: none;
          padding-left: 30px;
          padding-right: 30px;
          padding-bottom: 15px;
          border-bottom: 3px solid transparent;
          color: #828282;

          &.active {
            color: #fff;
            border-color: #fff;
          }

          &:hover {
            cursor: pointer;
          }

          @media (max-width: 679px) {
            font-size: 1rem;
            line-height: 1.5rem;
            }
        }
      }
    }
}