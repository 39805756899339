html, body {
  max-width: 100vw;
  overflow-x: hidden;
  font-size: 16px;

  [data-page="how-it-works"] {
    .bg-silver {
      background-color: #F2F2F2;
    }

    .justify-center {
      justify-content: center !important;
    }

    .p-1 { padding: 1em; }
    .p-2 { padding: 2em; }

    .py-5 { padding: 5em 0; }
    .py-4 { padding: 4em 0; }
    .py-3 { padding: 3em 0; }
    .py-2 { padding: 2em 0; }
    .py-1 { padding: 1em 0; }

    .px-1 {padding-left: 1em;padding-right: 1em;}
    .px-2 {padding-left: 2em;padding-right: 2em;}
    .px-3 {padding-left: 3em;padding-right: 3em;}
    .px-4 {padding-left: 4em;padding-right: 4em;}

    .mb-2 {margin-bottom: 2em;}

    .text-center {text-align: center;}
    .text-right {text-align: right;}

    .title {
      color: #023E8A;
      font-style: normal;
      font-weight: bold;
      font-size: 2.5em;
      line-height: 2.5em;
      margin: 0;

      &.center {
          text-align: center;
      }

      &.space-bottom {
        margin-bottom: 1.5em;
      }

      &.no-line-height {
        line-height: normal;
      }

      @media (max-width: 679px) {
          font-size: 1.75rem;
          line-height: 2rem;
      }
    }

    .btn-kb2 {
      font-family: 'Roboto', sans-serif;
      font-size: 1.5rem;
      font-weight: 400;
      border-radius: 50px;
      min-width: 250px;
      height: 70px;
      box-shadow: none;
      border: none;

      &:hover {
          cursor: pointer;
      }

      &.btn-blue {
          background-color: #0C74DD;
          color: white;

          &:hover {
              background-color: darken(#0C74DD, 10%);
          }
      }

      &.btn-orange {
          background-color: #F9AB2E;
          color: white;

          &:hover {
              background-color: darken(#F9AB2E, 10%);
          }
      }

      &.btn-xl {
        font-size: 1.35rem;
        min-width: 300px;
        height: 65px;
      }

      @media(max-width: 1366px) {
          min-width: 200px;
          height: 60px;

          &.btn-xl {
            min-width: 275px;
            height: 70px;
          }
      }
      
      @media(max-width: 640px) {
          font-size: 1.5rem;
          min-width: 150px;
          height: 40px;

          &.btn-xl {
              font-size: 1.5rem;
              min-width: 200px;
              height: 50px;
          }
      }
    }

    .banner {
      position: relative;
      height: 550px;
      overflow: hidden;

      img {
        min-width: 100%;
        height: 100%;
      }

      .content {
        position: absolute;
        width: 100%;
        height: 550px;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        > div {
          width: 80%;

          h1 {
            color: #fff;
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            line-height: 64px;
            text-align: center;
          }
        }
      }

      @media (max-width: 679px) {
        height: 380px;
        
        .content {
          height: 380px;
          
          > div {
            width: 90%;
            h1 {
              font-size: 2rem;
              line-height: 2.275rem;
            }
          }
        }
      }
    }

    .tab {
      .tab-button {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 35px;
        border: none;
        color: #828282;
        padding-bottom: 20px;
        border-bottom: 4px solid transparent;
        background-color: transparent;
    
        &.active {
          color: #000;
          border-color: #0D44AC;
        }
    
        &:hover {
          cursor: pointer;
        }

        @media (max-width: 679px) {
          font-size: 1.125rem;
          line-height: 1.25rem;
        }
      }

      p.text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
      }
    }

    .flow-invest {
      .text-title {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 23px;
        line-height: 33px;
        padding: 0 1.85rem;
        max-width: 285px;
        margin: 15px auto;

        @media (max-width: 679px) {
          max-width: 100%;
          margin: 15px auto;
        }
      }

      .list-items ul li {
        color: #4F4F4F;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        line-height: 1.85em;
        font-size: 1em;
        margin-bottom: 10px;
        
        &::marker {
          font-size: 1.5em;
          color: #4F4F4F;
        }
      }
    }

    .invest-step {
      .paper {
        height: 210px;
        border-radius: 1em;
      }
      .circle {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-color: #FFDDA6;

        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 1.5em;
        color: #000;
        line-height: 1.5em;
      }

      p.text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 1.35em;
        line-height: 1.65em;
        color: #4F4F4F;
      }
    }
  }
}