@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html, body {
    max-width: 100vw;
    overflow-x: hidden;
    font-size: 16px;

    
    @media(max-width: 1440px) {
        font-size: 14px;
    }
    
    @media(max-width: 640px) {
        font-size: 10px;
    }
}

[data-page="referal"] {
    .btn-kb {
        font-family: 'Roboto', sans-serif;
        font-size: 1.125rem;
        font-weight: 400;
        border-radius: 50px;
        min-width: 250px;
        height: 50px;
        box-shadow: none;
        border: none;

        &:hover {
            cursor: pointer;
        }

        &.btn-blue {
            background-color: #0C74DD;
            color: white;

            &:hover {
                background-color: darken(#0C74DD, 10%);
            }
        }

        &.btn-orange {
            background-color: #F9AB2E;
            color: white;

            &:hover {
                background-color: darken(#F9AB2E, 10%);
            }
        }

        &.btn-xl {
            font-size: 1.35rem;
            min-width: 300px;
            height: 65px;
        }

        @media(max-width: 1366px) {
            min-width: 200px;
            height: 60px;

            &.btn-xl {
                min-width: 275px;
                height: 70px;
            }
        }
        
        @media(max-width: 640px) {
            min-width: 150px;
            height: 40px;

            &.btn-xl {
                font-size: 1.25rem;
                min-width: 200px;
                height: 50px;
            }
        }
    }

    .header-page {
        .section-header {
            height: 760px;

            .title {
                padding: 8rem;

                h1 {
                    font-family: 'Raleway', sans-serif;
                    font-weight: 700;
                    font-size: 2.65rem;
                    line-height: 2.75rem;
                    color: #023E8A;
                }

                p {
                    font-family: 'Roboto', sans-serif;
                    font-weight: normal;
                    font-size: 1.125rem;
                    line-height: 2rem;
                    color: #333333;
                    margin-bottom: 2rem;
                }
            }

            @media(max-width: 1440px) {
                height: 650px;
            }

            @media(max-width: 1280px) {
                height: 580px;

                .title {
                    padding: 1.85rem;
                }
            }

            @media(max-width: 959px) {
                height: auto;

                .title {
                    padding: 1.5rem;
                }
            }

            @media(max-width: 640px) {
                height: auto;
                padding-bottom: 5rem;

                .title {
                    padding: 0rem 4rem;
                }
            }
        }
    }

    .refer-flow {
        background-color: #0077B6;
        padding-top: 6rem;
        padding-bottom: 10rem;

        h1 {
            font-family: 'Raleway', sans-serif;
            font-size: 2.0625rem;
            font-weight: 700;
            color: white;
            margin-bottom: 5rem;
        }

        .refer-flow-image {
            width: 100%;

            @media (max-width: 1366px) {
                width: 80%;
            }
        }

        .mobile-view {
            .item {
                margin-bottom: 5rem;

                img {
                    width: 100%;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .refer-friend {
        min-height: 75vh;

        h1 {
            font-family: 'Raleway', sans-serif;
            font-size: 2.0625rem;
            font-weight: 700;
            color: #023E8A;
        }

        h4 {
            font-family: 'Roboto', sans-serif;
            font-size: 1.75rem;
            font-weight: 400;
            color: #4F4F4F;
        }

        @media(max-width: 640px) {
            height: auto;
            padding-top: 20px;
            padding-bottom: 40px;
            
            h1 {
                font-size: 1.65rem;
                word-wrap: break-word;
            }

            h4 {
                font-size: 1.125rem;
            }
        }
    }
}