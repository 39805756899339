.campaign_image_container {
  height: 30vh;
  display: flex;
  flex-direction: column;
  /* background-color: black; */
}

.campaign_image {
  width: 100%;
  height: 30vh;
  opacity: 0.5;
  object-fit: cover;
  background-color: black;
}

.campaign-card {
  border-radius: 20px;
}

.campaign-acronim-type {
  /* position: relative;
    top: 20vh;
    color: white;
    left: 15px; */
  position: absolute;
  left: 20px;
  bottom: 10px;
  color: white;
}

.campaign-acronim-type div:first-child {
  font-size: 1.5rem;
  font-weight: 700;
}

.campaign-acronim-typ div:not(:first-child) {
  font-size: 1.125rem;
}

.campaign-content {
  padding: 4vh;
}

.campaign-content .sme_bar {
  border-bottom: 1px solid #e0e0e0;
}

.campaign-content .campaign__bar .progress {
  height: 25px !important;
  border-radius: 20px !important;
  background-color: #e8e6e6 !important;
  margin-bottom: 0 !important;
}

.campaign-content .campaign__bar .progress .MuiLinearProgress-bar {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.labels {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2vh;
}

.f-12 {
  font-size: 12px !important;
}

.campaign__info {
  margin: 2vh 0;
}

.campaign__info-icon {
  color: #bdbdbd;
}

.info_grid {
  margin-top: 2vh !important;
}

.labeled-info__label {
  color: #828282;
  font-size: 0.875rem;
  margin-bottom: 0;
}

.campaign__info-info {
  color: #828282;
}

.labeled-info__risk {
  border-radius: 4px;
  border: 1px solid;
  padding: 0 1rem;
}

.risk-a {
  color: #2f80ed;
  /* border-color: #2F80ED; */
  border: none;
}

.risk-b {
  color: #0077b6;
  /* border-color: #0077B6; */
  border: none;
}

.risk-c {
  color: #f2c94c;
  /* border-color: #F2C94C; */
  border: none;
}

.PrivateTabIndicator-root-2 {
  height: 4px !important;
}

.button-tabs-campaign a {
  width: 100%;
}
.button-tabs-campaign a button {
  width: 100%;
  border-radius: 50px;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  box-shadow: none;
  height: 50px;
  border: none;
  margin-top: 2vh;
  cursor: pointer;
}
.button-tabs-campaign a button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.campaign_image_container {
  position: relative;
  display: block;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  height: 13rem;
}

.campaign_image_container img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.campaign_image_container_sme {
  background-color: #2c2734;
}

.campaign_image_container_sme img {
  opacity: 0.7;
}

.truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.load-more {
  height: 50px;
  border-radius: 50px !important;
  padding: 0 5rem !important;
  color: #828282 !important;
  font-weight: 400 !important;
  text-transform: none !important;
}

.load-more:disabled {
  border-color: #cacaca !important;
  color: #cacaca !important;
}
