[data-page="faq"] {
  .header {
    display: table;
    position: relative;
    width: 100%;
    height: 95vh;
    overflow: hidden;

    .header-background {
        position: absolute;
        width: auto;
        height: auto;
        min-width: 100%;
        min-height: 100%;
    }

    .header-body {
        display: table-cell;
        position: relative;
        vertical-align: middle;
        text-align: center;
        width: 100%;
        padding: 20px;
        z-index: 9;

        h1 {
            font-family: 'Raleway';
            font-size: 48px;
            font-weight: 700;
            line-height: 64px;
            color: #fff;
        }
    }

    @media (max-width: 679px) {
        height: 385px;

        .header-background {
            left: 0;
        }

        .header-body {
            h1 {
                font-size: 3rem;
            }
        }
    }
  }

    .content-body {
        position: relative;
        top: -75px;
        background-color: white;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        z-index: 9;

        .tab-space {
            .MuiGrid-item {
                padding: 40px;
            }
        }

        .tab-button-item {
            width: 200px;
            height: 210px;
            border-radius: 30px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            text-align: center;

            position: relative;
            top: -75px;

            > span {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 1rem;
                color: #BDBDBD;

                &.tab-title {
                    width: 70%;
                    line-height: 1.5rem;
                }
            }

            &.active {
                > span {
                    color: #0D44AC;
                }
            }

            &:hover {
                cursor: pointer;
            }

            @media (max-width: 679px) {
                width: 120px;
                height: 130px;
                top: -40px;
                
                svg {
                    width: 50px;
                    height: 58px;
                }

                > span {
                    font-size: 0.85rem;
                }
            }
        }
        @media (max-width: 679px) {
            top: -40px;

            .tab-space {
                .MuiGrid-item {
                    padding: 15px;
                }
            }
        }

        .sme-tab-button {
            font-family: 'Raleway';
            font-style: normal;
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 2rem;
            color: #BDBDBD;
            padding-bottom: 1.25rem;
            border-bottom: 3px solid transparent;
            
            &.active {
                border-color: #0D44AC;
                color: #0D44AC;
            }

            @media (max-width: 679px) {
                font-size: 1.115rem;
            }
        }

        .tabpanel {
            margin-top: 4rem;

            .tabitem {
                border-radius: 10px;
                max-height: 100px;
                overflow: hidden;
                transition: all 0.5s;
                
                .chev-icon {
                    transform: rotate(0);
                    transition: all 0.3s;
                }

                &.show {
                    max-height: 1000px;

                    .chev-icon {
                        transform: rotate(90deg);
                    }

                    .tab-content {
                        .header {
                            color: #0D44AC;
                        }
                    }
                }

                &:hover {
                    cursor: pointer;
                }

                .tab-content {
                    padding: 0 2em;

                    .header {
                        height: 100px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.188rem;
                        line-height: 1.625rem;

                        .head-title {
                            width: 75%;
                        }

                        @media (max-width: 679px) {
                            font-size: 1.25rem;
                            line-height: 1.75rem;

                            .head-title {
                                width: 85%;
                            }
                        }
                    }

                    .content {
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: 24px;
                        margin-top: 15px;

                        &.title-small {
                            position: relative;
                            top: -18px;
                        }
                    }

                    @media (max-width: 679px) {
                        padding: 0 1em;
                    }
                }
            }
        }
    }
}