@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
    font-size: 16px !important;
}

[data-page="about-us"] {
    font-family: 'Raleway', sans-serif;

    .break {
        flex-basis: 100%;
        height: 0;
    }

    .mui-justify-center {
        justify-content: center;
    }

    section {
        padding: 50px 0;

        @media (max-width: 679px) {
            padding: 35px 0;
        }
    }

    .header {
        display: table;
        position: relative;
        width: 100%;
        height: 70vh;
        overflow: hidden;

        .header-background {
            position: absolute;
            width: auto;
            height: auto;
            min-width: 100%;
            min-height: 100%;
        }

        .header-body {
            display: table-cell;
            position: relative;
            vertical-align: middle;
            text-align: center;
            width: 100%;
            padding: 20px;
            z-index: 9;

            h1 {
                font-family: 'Raleway';
                font-size: 2.5rem;
                color: #fff;
            }
        }

        @media (max-width: 679px) {
            height: 300px;

            .header-background {
                left: 0;
            }

            .header-body {
                h1 {
                    font-size: 3rem;
                }
            }
        }
    }

    .sub-header {
        background-color: #0D44AC;
        padding: 30px;
        margin-bottom: 30px;

        p {
            font-family: 'Roboto', sans-serif;
            font-size: 1.188rem;
            font-weight: 400;
            line-height: 1.813rem;
            color: #fff;
        }
    }

    .center-content {
        display: table;
        width: 100%;
        height: 100%;

        .body {
            display: table-cell;
            vertical-align: middle;

            &.space-left {
                padding-left: 2rem;
            }
        }

        @media (max-width: 679px) {
            .body {
                &.space-left {
                    padding-left: 0;
                }
            }
        }
    }

    .title {
        color: #023E8A;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 2.5rem;

        &.center {
            text-align: center;
        }

        @media (max-width: 679px) {
            font-size: 1.75rem;
            line-height: 2rem;
        }
    }

    p {
        color: #4F4F4F;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.625rem;
        text-align: justify;

        &.center {
            text-align: center;
        }

        @media (max-width: 679px) {
            font-size: 1rem;
            line-height: 1.625rem;
        }
    }

    .the-team {
        margin-bottom: 50px;

        .team {
            text-align: center;
            
            .team-photo {
                margin-top: 25px;
                width: 230px;
            }

            p.team-name {
                text-align: center;
                font-size: 1.5rem;
                margin-bottom: 15px;
            }
            
            p.team-position {
                text-align: center;
                font-weight: bold;
                margin-bottom: 10px;
            }
        }
    }
}